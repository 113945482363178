import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Detect the browser's language setting
      const browserLanguage = navigator.language || navigator.userLanguage;
      const preferredLanguage = browserLanguage.startsWith("es") ? "es" : "en";

      // Set the language based on the browser's language, defaults to English
      setLanguage(preferredLanguage);
    }
  }, []);

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLanguage = () => useContext(LanguageContext);
